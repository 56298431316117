import React from 'react'
import { graphql } from 'gatsby'
import { get } from 'lodash'
import { Default as Layout } from 'components/layout/Layout'
import { InstitutionalArticle } from 'components/section/Article'

const Institutional = ({ pageContext = {}, location }) =>
  <Layout location={ location }>
    <InstitutionalArticle
      location={ location }
      data={ get(pageContext, 'data.article') }
    />
  </Layout>

export default Institutional
